<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>

    <div class="content-container">
      
      <!-- <div class="flex text-center">
        <div :class="['tab', current == 'traffic' ? 'active' : '',]" @click="tabChange('traffic')">
          <div class="tab-icon">
            <img src="https://szcwcdn.szcreativeweek.com/f1h0bvkatg1646989078932.jpg" alt="" class="fill">
          </div>
          <div class="text-bold">便捷交通</div>
        </div>
        <div :class="['tab', current == 'plane' ? 'active' : '',]" @click="tabChange('plane')">
          <div class="tab-icon">
            <img src="https://szcwcdn.szcreativeweek.com/91vks1g5v81646989122394.jpg" alt="" class="fill">
          </div>
          <div class="text-bold">特惠机票</div>
        </div>
        <div :class="['tab', current == 'hotel' ? 'active' : '',]" @click="tabChange('hotel')">
          <div class="tab-icon">
            <img src="https://szcwcdn.szcreativeweek.com/6cbp48730g1646989111979.jpg" alt="" class="fill">
          </div>
          <div class="text-bold">超值酒店</div>
        </div>
      </div> -->
      
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/traffic/bus.png" alt="便捷交通" class="title-img">
      <div ref="traffic" class="section">
        
        <div class="tips text-bold">温馨提醒: 由于展会现场交通相对拥堵且停车位有限，主办方建议大家乘坐公共交通绿色出行。</div>

        <div class="row text-bold">1、地铁</div>
        <div class="end-row">12号线/20号线【国展】C1/C2口，【国展北】D口出站，直达深圳国际会展中心。</div>
        
        <div class="row text-bold">2、出租车</div>
        <div class="end-row">请将目的地设置为深圳国际会展中心（宝安区福海街道展城路1号），下客区南登录大厅东侧。</div>
        
        <div class="row text-bold">3、城际交通</div>
        <div>● 高铁</div>
        <div>抵达深圳北高铁站后，乘坐地铁5号线（赤湾方向），在【灵芝】转12号线（海上田园东方向），在【国展】C1/C2口，出站，用时约1小时8分钟。</div>
  
        <div>● 机场</div>
        <div class="end-row">换乘地铁11号线（碧头方向），在【机场北】转20号线（会展城方向），在【国展】C1/C2口，出站，用时约22分钟。</div> 
      
        <div class="row text-bold">4、公交路线</div>
        <div class="end-row">M515、615、国际会展接驳专线1号</div>
        <div class="end-row"></div>

        <div class="row text-bold">5、自驾</div>
        <div>导航至：深圳市宝安区福永街道展城路1号深圳国际会展中心（直接链接导航至展馆14号门）</div>
        <div>● 地面停车路线：</div>
        <div>※由桥和路、展云路 → 展景路 → 滨江大道4号门进入停车</div>
        <div>● 地下停车路线：</div>
        <div>※由国展立交 → 凤塘大道 → 展城路→P1地下停车场停放;</div>
        <div>※由国展立交 → 滨江大道 → 展城路 → P5地下停车场停放;</div>
        <div>※由桥和路、展云路 → 展景路 → 滨江大道 → P4地下停车场停放。</div>
        <div class="end-row"></div>
        <div class="end-row"></div>

        <div class="row text-bold">温馨提醒：</div>
        <div class="end-row">
          *非深号牌小车在工作日早高峰7:00-9:00、晚高峰17:30-19:30期间，在深圳全市所有区域的所有道路禁止行驶。其余时间段深圳市内不限行（非深号牌小车如仅在非限行时段行驶，则无需申请通行证）。
        </div>
        <div class="end-row">
          如何申请通行证：您可关注“深圳交警”微信公众号了解申领事宜。点击菜单栏中星级用户→登录/注册→更多业务 → 申请通行证(外地车)，即可进入申请入口，每月只能申请一次(一天) 免限行，且只能申请一个月内的其中一天。
        </div>

        

      </div>
      
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/traffic/plane.png" alt="特惠机票" class="title-img">
      <div ref="plane" class="section">

        <div class="row text-bold">展会专属增值服务</div>
        <div class="end-row">深圳家居设计周暨第 40 届深圳国际家具展与深圳航空达成战略合作，为来自全国的参展商及专业买家提供机票预订等服务，更好地为大家节省展会期间出行开支，专享深航、携程商旅会展合作优惠。</div>
        
        <div class="row text-bold">优惠对象:</div>
        <div class="end-row">2025年所有深圳家居设计周暨第40届深圳国际家具展【展商】和【观众】</div>
        
        <div>
          <div class="row text-bold text-center"><h2 class="text-bold">深航专线</h2></div>
          <div class="end-row flex justify-center">
            <img src="https://exhoss39.szcreativeweek.com/applet/img/plane/sh-logo.png" alt="" class="t-log1">
            <!-- <img src="https://exhoss39.szcreativeweek.com/applet/img/plane/ub-logo.png" alt="" class="t-log2"> -->
          </div>
          
          <div class="end-row text-center"># 深圳航空 - 官方指定战略航司 #</div>
          <!-- <div class="end-row">深圳航空作为本届深圳家居设计周的官方指定战略航司，为参加2025年深圳家居设计周的所有参展商提供优惠机票及优质服务，具体订票优惠政策待主办方通知。</div> -->
          <div class="row text-bold">一、展期优惠政策: </div>
          
          <div class="row text-bold">1 、适用时间：</div> 
          <div class="row">销售时间：即日起-2025年3月24日</div>
          <div class="end-row">乘机时间：2025年3月11日--2025年3月24日</div>
          
          <div class="row text-bold">2、适用航线及订票折扣</div>
          <table class="table" border="0" cellspacing="0" cellpadding="0" style="width: 100%; margin: 0 auto; align: center; vertical-align: middle; line-height: 2em;">
            <tr style="background-color: #C7DAF1; line-height: 3em;">
              <th>适用航线</th>
              <th>适用舱位</th>
              <th>票面减幅</th>
              <th>备注</th>
            </tr>
            <tr>
              <td align="center" rowspan="4">
                <div class="">深圳--全国</div>
                <div class="">例外航线除外</div>
              </td>
              <td align="center">J（公务舱）</td>
              <td align="center">15%</td>
              <td align="center" rowspan="4">单去、单回</td>
            </tr>
            <tr>
              <td align="center">Y（经济舱）</td>
              <td align="center">10%</td>
            </tr>
            <tr>
              <td align="center">B/M/U/H/Q（经济舱）</td>
              <td align="center">7%</td>
            </tr>
            <tr>
              <td align="center">V/W/S 经济舱</td>
              <td align="center">5%</td>
            </tr>
          </table>
          <div class="row">备注：</div>
          <div class="row">① 特价机票不再享受以上折上折优惠；</div>
          <div class="end-row">② 例外航线详情可咨询订票热线。</div>
          
          <div class="row text-bold">3、订票方式</div>
          <div class="row">● 深圳航空指定优质服务商—深圳市优行商旅科技有限公司 24小时订票服务热线：400 666 3232、0755-36861999，专属客户经理：0755-26585191。</div>
          <div class="end-row">报“2025深圳家居设计周”专属热线提供机票查询、预定、改期、退票、出票服务。</div>
          
          <div class="row">※ 注意 Notice：</div>
          <div class="end-row">机票退改签：按照实际承运折扣退改签规则为准</div>
          
          <div class="row text-bold">二、非展期优惠政策: </div>
          <div class="row">贵司成为深航大客户（需与深航签订大客户协议），可以享受以下权益（合同签订起一年内，具体优惠时间以企业签订的大客户协议为准）：</div>
          <div class="row"><span class="text-bold">1.机票优惠：</span>通过有资质的服务商（可以是企业自己合作的专属订票服务机构）订购深航，国航机票可以享受折上折优惠，一般优惠幅度在5-10%（具体折扣以最终订票价格为准）；</div>
          <div class="row"><span class="text-bold">2.领导保障：</span>重要领导可以备案深航贵宾，免费享受深航高端服务（名额配置根据企业年度采购量而定）；</div>
          <div class="row"><span class="text-bold">3.员工福利：</span>企业员工通过深航APP认证，可以享受因私购票优惠7-8%，优先参加凤凰知音会员快速升级活动（具体操作深航工作人员将与企业对接人联系）。</div>
          <div class="end-row">如需办理以上权益，可联系深航专属客户经理：<span class="text-bold">田经理 13714034033</span></div>
          
        </div>
        
        <!-- <div style="padding-top: 50px;">
          <div class="row text-bold text-center"><h2 class="text-bold">携程商旅</h2></div>
          <div class="end-row flex justify-center">
            <img src="https://exhoss39.szcreativeweek.com/applet/img/plane/trip-logo.png" alt="" class="t-log5">
          </div>
          
          <div class="end-row text-center"># 携程商旅 - 大会优选商旅服务商 #</div>
        
          <div class="row text-bold">一、服务对象：仅限公司客户（TO B），不针对散客注册用户</div>        
          <div class="row text-bold">二、出行福利: </div>
          <div class="row">1. 0 门槛免费注册：注册成功可享500元注册礼包（机票、酒店预订满减抵扣券）；</div>
          <div class="row">2. 预订差旅行程后次月将另赠500元展会专享券包；</div>
          <div class="row">3. 尊享多项商旅协议价格；</div>
          <div class="row">4. 注册后领取携程商旅&WPS365联名权益：3个月免费试用WPS365价值199/人/年的商业基础版套餐；</div>
          <div class="end-row">备注：联名权益需咨询客户经理领取（注册完毕后会邮件通知所属客户经理信息）或咨询10106016。</div>
      
          <div class="row text-bold">三、注册流程: </div>
          <div class="end-row text-center">
            <img src="https://exhoss39.szcreativeweek.com/applet/img/plane/trip-qrcode.png" alt="" class="trip-qrcode">
            <div class="" >扫描以上专属二维码输入手机号码进行免费注册</div>
          </div>
        
          <div class="">温馨提示：本优惠最终解释权归深圳展主办方及上海携程宏睿国际旅行社有限公司所有；以上服务如有调整，请以最新公告为准。</div>
        </div> -->

      </div>
      
      <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/traffic/hotel.png" alt="超值酒店" class="title-img">
      <div ref="hotel" class="section"></div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'traffic',
    }
  },
  created() {

  },
  methods: {
    tabChange(current) {
      console.log(current);
      this.current = current;
    },
  },
}
</script>

<style scoped lang="less">

// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .tab {
      width: 110px;
      box-sizing: border-box;
      margin: 7px;
      padding: 5px;
      font-size: 14px;
      cursor: pointer;
      border-radius: 6px;
      
      .tab-icon {
        width: 100%;
        height: auto;
        background-color: #efefef;
        margin-bottom: 5px;
        border-radius: 4px;
        overflow: hidden;
      }
      
      &.active {
        box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
      }
    }
    
    .title-img {
      display: block;
      width: 100%;
      height: auto;
    }
    
    .section {
      width: 375px;
      margin: 0 auto 20px;
      padding: 10px;
      font-size: 14px;
      
      h2 {
        margin: 30px 0 10px;
        font-size: 18px;
      }
      .tips {
        margin: 10px 0 30px;
      }
      
      .row {
        margin: 5px 0;
      }
      .end-row {
        margin-bottom: 15px;
      }
      
    }
    
    .t-log1 {
      width: 60px;
      height: auto;
      margin-right: 20px;
    }
    .t-log2 {
      width: 100px;
      height: auto;
      margin-top: 3px;
    }
    .t-log3 {
      width: 80px;
      height: auto;
      margin-right: 20px;
    }
    .t-log4 {
      width: 60px;
      height: auto;
    }
    .t-log5 {
      width: 172px;
      height: auto;
    }
    
    .price-table {
      width: 100%;
      height: auto;
    }
    .by-applet {
      width: 150px;
      height: auto;
    }
    
    .trip-qrcode {
      width: 160px;
      height: auto;
    }
    
  }
  
  .table {
    font-size: 12px;
    border: 1px solid #eee;
    display: table;
     
    th,td {
      display: table-cell;
      border: 1px solid #eee;
    }
  }
}

@media screen and (min-width: 1024px) {
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 270px;
    margin: 0 auto 20px;
    
    .tab {
      width: 33%;
      box-sizing: border-box;
      margin: 6px;
      padding: 4px;
      font-size: 5.4px;
      cursor: pointer;
      border-radius: 2px;
      
      .tab-icon {
        width: 100%;
        height: 50px;
        background-color: #efefef;
        margin-bottom: 4px;
        border-radius: 2px;
        overflow: hidden;
      }
      
      &.active,
      &:hover {
        box-shadow: 0 1px 5px rgba(0, 0, 0, .2);
      }
    }
    
    .title-img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 1px;
    }
    .section {
      width: 240px;
      margin: 0 auto 20px;
      padding: 10px;
      font-size: 3.3px;
      
      h2 {
        margin-top: 10px;
      }
      .tips {
        margin-bottom: 10px;
      }
      
      .row {
        margin-bottom: 1px;
      }
      .end-row {
        margin-bottom: 4px;
      }
      
    }
    
    .t-log1 {
      width: 12px;
      height: auto;
      margin-right: 3px;
      margin-top: 3px;
    }
    .t-log2 {
      width: 20px;
      height: auto;
      margin-top: 3px;
    }
    .t-log3 {
      width: 16px;
      height: auto;
      margin-right: 3px;
      margin-top: 3px;
    }
    .t-log4 {
      width: 12px;
      height: auto;
      margin-top: 3px;
    }
    .t-log5 {
      width: 60px;
      height: auto;
      margin-top: 3px;
    }
    
    .price-table {
      width: 100%;
      height: auto;
    }
    .by-applet {
      width: 30%;
      height: auto;
    }
    
    .trip-qrcode {
      width: 40px;
      height: auto;
    }
    
    .hotel {
      display: block;
      width: 60%;
      margin: 0 auto;
    }
    
  }
  
  .table {
    font-size: 3.2px;
    border: 0.1px solid #eee;
    display: table;
     
    th,td {
      display: table-cell;
      border: 0.1px solid #eee;
    }
  }
}
</style>